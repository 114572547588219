// var $ = require("jquery");
// import Swiper from 'swiper/bundle';
// import 'swiper/swiper-bundle.css';
import { header } from './modules/header';
import { animation } from './modules/animation';

if (document.querySelector(".l-header_btn") != null) {
    header();
}
animation();
// window.onload = () => {
//     const loader = document.querySelector('.loading');
//     const mv = document.querySelector('.j-mv');
//     loader.classList.remove('loading');
//     mv.classList.remove('is-active');
// };